@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800");

body {
  height: 100vh;
  overflow: hidden;
  background-color: #fff9ce;
  font-family: "Poppins", sans-serif;
}

.swiper {
  width: 100vw;
  height: 100vh;

  .swiper-slide {
    width: 100vw;
    height: 100vh;

    > img {
      width: 100vw;
      height: 100vh;
      object-fit: contain;
    }

    > iframe {
      width: 100% !important;
      margin: 0 !important;
      pointer-events: none;
    }
  }
}

.react-pdf__Document {
  height: calc(100vh - 40px);
  padding: 20px;

  .react-pdf__Page {
    height: 100%;
  }

  .react-pdf__Page__canvas {
    //height: 100% !important;
    //width: auto !important;
    margin: 0 auto;
  }
}

.ig-feed {
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 20px;

  > div {
    > div {
      > div {
        flex-basis: calc(100% / 5 - 10px);
      }
    }
  }
}

.fb-feed {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  padding-top: 20px;

  .fb-post {
    display: flex;
    flex-direction: column;
    //max-width: 300px;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #fad900;

    &__header {
      background-color: #fad900;
      color: #000;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
    }

    &__user {
      font-size: 12px;
    }

    &__date {
      font-size: 11px;
    }

    &__content {
      background-color: #fff;
      font-size: 14px;
      text-align: left;
      padding: 0 10px;
    }

    &__image {
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        display: block;
      }
    }

    &__footer {
      background-color: #fad900;
      color: #000;
      font-size: 12px;
      text-align: left;
      padding: 10px;
    }
  }
}

.my-masonry-grid_column {
  padding-left: 10px;
  padding-right: 10px;
  background-clip: padding-box;
}

video {
  max-width: 100vw;
  max-height: 100vh;
}

.image-wrapper {
  width: 100vw;
  height: 100vh;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.unauthorized {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &__content {
    font-size: 30px;
    padding: 0 100px;

    .code {
      font-size: 40px;
      font-weight: bold;
    }

    button {
      color: #000;
      background: #fabf00;
      font-weight: bold;
      border: 2px solid #fabf00;
      padding: 20px 40px;
      border-radius: 30px;
      margin-top: 30px;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        background: transparent;
      }
    }
  }
}

.wp-post {
  max-width: 1400px;
  margin: 0 auto;

  h1 {
    font-size: 40px;
    font-weight: 800;
    line-height: 54px;
  }

  .wp-post__main {
    display: flex;
    text-align: left;
    background-color: #fff;
    border-radius: 0 0 16px 16px;

    &--left {
      width: 33.333%;
      position: relative;
    }

    &--right {
      width: 66.666%;
      position: relative;
      padding: 0 30px 30px 30px
    }
  }

  &__date {
    background: url("../img/post-date-bg.svg") right center no-repeat;
    position: absolute;
    top: 20px;
    left: 0;
    height: 50px;
    font-size: 14px;
    font-weight: 800;
    display: flex;
    align-items: center;
    padding: 0 24px 0 8px;
  }

  &__thumbnail {
    img {
      max-width: 100%;
      display:block;
    }
  }

  &__excerpt {
    p {
      border-bottom: 2px solid #fabf00;
      font-size: 14px;
      font-style: italic;
      padding: 30px 0;
      margin: 0 0 30px 0;
    }
  }

  &__content {
    font-size: 14px;
  }
}
